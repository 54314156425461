.section_head-title {
  font-size: 2.6rem;
  font-weight: 700;
  text-align: center;
  color: var(--color-black-2);
  text-transform: capitalize;
  @media screen and (min-width: 1024px) {
    font-size: 4rem;
  }

  span {
    color: var(--color-secondary);
    font-family: var(--font-cursive);
    font-size: 4rem;
  }

  .section_head-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
  }
}
