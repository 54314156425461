@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-ExtraBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI Semi";
  src: url("./assets/fonts/THICCCBOI-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-ThicccAF.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "THICCCBOI";
  src: url("./assets/fonts/THICCCBOI-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-base: "DM Sans", sans-serif;
  --font-secondary: "THICCCBOI";
  --font-cursive: "Allura", cursive;

  --color-black: #000000;
  --color-black-1: #141414;
  --color-black-2: #030303;
  --color-black-3: #222222;
  --color-black-4: #4a4a4a;
  --color-white: #ffffff;
  --color-white-1: #f2f7f9;
  --color-white-2: #f3f3f3;
  --color-white-3: #efefef;
  --color-white-4: #fbfbfb;
  --color-white-5: #f4f4f4;
  --color-white-6: #c6c5c5;
  --color-primary: #63c1e5;
  --color-secondary: #20a8dc;
  --color-secondary-1: #1493c5;
  --color-grey: #6b7688;
  --color-grey-1: #e4e4e4;
  --color-grey-2: #9498ad;
  --color-grey-3: #b6b3be;
  --color-grey-4: #d9d9d9;
  --color-grey-5: #999999;
  --color-grey-6: #5b5b5b;
  --color-brown: #46364a;

  --color-background-navbar: rgba(255, 255, 255, 0.25);
  --color-border-navbar: rgba(255, 255, 255, 0.2);
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html {
  font-family: var(--font-secondary);
  font-size: 10px;
}

a {
  text-decoration: none;
}
