.app__about_page {
  background-color: var(--color-white);

  .app__my_story {
    padding-bottom: 80px;
    .my_story_wrap {
      display: flex;
      flex-direction: column-reverse;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }
      .my_story_text {
        h1 {
          font-family: var(--font-secondary);
          font-size: 3.5rem;
          font-weight: 700;
          margin-bottom: 2.5rem;
          span {
            font-size: 2rem;
          }
        }
        p {
          font-size: 1.6rem;
          font-weight: 300;
        }
      }
      .my_story_img {
        position: relative;
        img {
          max-width: 100%;
          border-radius: 6px;
          @media screen and (min-width: 1024px) {
            max-width: none;
            border-radius: 0;
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
          }
        }
        .img_blur {
          display: none;
          @media screen and (min-width: 1024px) {
            display: block;
            width: 40%;
            height: 100%;
            /* From https://css.glass */
            background: rgb(255, 255, 255);
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 1) 20%,
              rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }
      }
    }
  }

  .app__worked_with {
    margin-right: auto;
    margin-left: auto;
    .worked_with_wrap {
      display: block;
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      h1 {
        font-size: 2rem;
        font-weight: 500;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 4rem;
        @media screen and (min-width: 1024px) {
          width: auto;
          text-align: left;
          margin-bottom: 0;
        }
      }
      .worked_with_img {
        border-radius: 4px;
        background: rgba(241, 241, 241, 0.25);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);

        width: 48%;
        height: 150px;
        display: inline-flex;
        justify-content: center;
        // text-align: center;
        margin-bottom: 1rem;
        @media screen and (min-width: 1024px) {
          width: auto;
        }

        &:nth-child(2n) {
          margin-right: 1rem;
          @media screen and (min-width: 1024px) {
            margin: 0;
          }
        }

        @media screen and (min-width: 1024px) {
          padding: 10px;
        }

        img {
          object-fit: contain;
          filter: grayscale(0.9);
        }
      }
    }
  }

  .app__about_awards {
    margin-right: auto;
    margin-left: auto;
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
    .awards {
      width: 100%;
      display: grid;
      margin-top: 80px;
      margin-right: auto;
      margin-left: auto;
      grid-auto-columns: 1fr;
      column-gap: 60px;
      row-gap: 16px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      .award_box {
        min-height: 400px;
        padding: 35px;
        text-align: left;
        background: url("../../assets/awardsBg1.svg") no-repeat;
        background-color: var(--color-white-4);
        border-radius: 2px;
        background-position: 0 100%;
        background-size: auto;
        box-shadow: 2px 2px 10px 2px var(--color-white-5);
        &:nth-child(even) {
          background: url("../../assets/awardsBg2.svg") no-repeat;
          background-size: cover;
          background-position: 100% 0;
        }
        svg {
          width: 60px;
          height: 60px;
          margin-bottom: 30px;
          // color: #f1ce07;
          color: var(--color-black-4);
        }
        h4 {
          margin-top: 0;
          margin-bottom: 20px;
          font-size: 2.4rem;
          line-height: 32px;
          font-weight: 700;
        }
        p.text {
          font-size: 1.4rem;
          line-height: 24px;
          font-weight: 400;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
