#about {
  .app__wrapper {
    padding-left: 0;
    padding-right: 0;
    &.app__flex {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      .app__about {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        @media screen and (min-width: 1024px) {
          display: flex;
          width: 1090px;
        }

        .app__about_img {
          display: none;
          @media screen and (min-width: 1024px) {
            display: block;
            position: relative;
            align-self: center;
            justify-self: center;
            max-width: 450px;
            margin-right: 2rem;
            img {
              max-width: 100%;
              object-position: 100% 100%;
              object-fit: cover;
              filter: grayscale(0.55);
              border-radius: 1rem;
            }
          }
        }

        .app__about_content {
          flex: 0.75;
          h3 {
            font-family: var(--font-thiccboi);
            font-size: 1.6rem;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0;
            text-transform: uppercase;
          }
          p {
            font-family: var(--font-secondary);
            font-size: 1.6rem;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0;
          }
          .primary__text {
            font-family: var(--font-secondary);
            font-size: 5.4rem;
            line-height: 70px;
            font-weight: 700;
            color: var(--color-secondary);
            @media screen and (min-width: 1024px) {
              font-size: 5rem;
            }
          }
          .sub__text {
            margin-bottom: 30px;
            font-size: 1.6rem;
            font-weight: 700;
            color: var(--color-secondary);
            a {
              text-decoration: none;
              color: #ff2196;
            }
          }
          .full__content {
            margin-bottom: 35px;
            font-size: 1.6rem;
            text-align: justify;
            @media screen and (min-width: 768px) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
