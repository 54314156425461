.app__contact_page {
  background: var(--color-white);
  .app__contact_wrap {
    padding-top: 4rem;
    padding-bottom: 6rem;
    .contact {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }
      .contact_form {
        width: 100%;
        padding: 0 20px;
        h2 {
          font-size: 2rem;
          margin-bottom: 1.5rem;
        }

        @media screen and (min-width: 1024px) {
          min-width: 450px;
        }
      }
      .contact_follow {
        text-align: center;
        margin: 4rem 0 3rem;
        @media screen and (min-width: 1024px) {
          text-align: center;
        }
        h2 {
          font-size: 2rem;
          margin-bottom: 1.5rem;
        }
        .social__links_cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .social__links_card {
            display: flex;
            align-items: center;
            background: var(--color-white);
            width: auto;
            margin-bottom: 0.6rem;
            padding: 10px 12px;
            border-radius: 4px;
            text-decoration: none;
            font-size: 1.4rem;
            font-weight: 500;
            box-shadow: 1px 1px 5px 1px var(--color-white-3);
            margin-right: 12px;
            margin-bottom: 12px;
            img {
              margin: 0;
              @media screen and (min-width: 1024px) {
                margin-right: 1rem;
              }
            }
            span {
              display: none;
              color: #14171a;
              font-size: 1.8rem;
              font-weight: 500;

              @media screen and (min-width: 1024px) {
                display: block;
              }
            }

            @media screen and (min-width: 1024px) {
              min-width: 200px;
              padding: 15px 18px;
            }
          }
        }
      }
    }
  }
}
