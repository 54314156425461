.content_pane {
  display: none;
  &_active {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
    .pane__img {
      flex: 0.4;
      padding: 0 6rem;
      @media screen and (min-width: 1024px) {
        margin-right: 1.5rem;
      }
      img {
        max-width: 100%;
      }
    }
    .pane__content {
      display: flex;
      flex: 0.5;
      flex-direction: column;
      justify-content: center;
      h6 {
        font-size: 1.8rem;
        line-height: 1.5em;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.7rem;
        line-height: 1.5em;
        :last-child {
          margin: 0;
        }
      }
    }
  }
}
