#services {
  .app__wrapper {
    padding-left: 0;
    padding-right: 0;
    .app__service {
      margin-top: 4rem;
      flex-direction: column;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }

      .app__service_nav {
        flex: 0.4;
        .app__service_nav-pills {
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: left;
          flex-wrap: nowrap;
          @media screen and (min-width: 1024px) {
            flex-direction: column;
          }
        }
      }

      .app__service_content {
        flex: 0.6;
        margin-left: 0;
        @media screen and (min-width: 1024px) {
          margin-left: 2rem;
        }
      }
    }
  }
}
