#experience {
  min-height: auto;
  .app__wrapper {
    padding-left: 0;
    padding-right: 0;
    &.app__flex {
      display: block;
    }
  }

  .page__header_desc {
    display: block;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 1024px) {
      display: flex;
    }

    @media screen and (max-width: 1300px) {
      position: relative;
      max-width: 1170px;
    }

    .page__header_desc-stats {
      display: none;
      @media screen and (min-width: 1024px) {
        display: flex;
        .page__header_desc-stats-item {
          display: flex;
          margin-left: 50px;
          align-items: center;
        }

        .page__header_desc-stats-number {
          font-size: 11.6rem;
          font-weight: 300;
          margin-right: 20px;
          font-family: var(--font-secondary);
        }

        .page__header_desc-stats-text {
          max-width: 100px;
          font-family: var(--font-secondary);
          font-size: 1.4rem;
          line-height: 1.24;
        }
      }
    }

    .page__header_desc-text {
      max-width: 380px;
      font-family: var(--font-secondary);
      line-height: 1.24;
      font-size: 1.6rem;
      a {
        font-weight: 500;
        color: var(--color-primary);
      }
    }
  }

  .page__resturants {
    width: 100%;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: transparent;
    @media screen and (min-width: 1024px) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    .resturants__container {
      @media screen and (max-width: 1300px) {
        position: relative;
        max-width: 1170px;
      }
      .resturants_flex {
        display: grid;
        grid-template-columns: auto auto auto;
        @media screen and (min-width: 1024px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        img {
          display: block;
          width: 100px;
          @media screen and (min-width: 1024px) {
            width: 130px;
          }
        }
      }
    }
  }

  .exp__mission_section {
    display: block;
    width: 100%;
    .exp__mission_container {
      .exp__mission_wrap {
        display: flex;
        justify-content: flex-end;
        .exp__mission {
          width: 100%;
          position: relative;
          display: block;
          @media screen and (min-width: 1024px) {
            width: 840px;
            display: flex;
            justify-content: flex-end;
          }
          h2 {
            display: block;
            font-family: var(--font-base);
            font-size: 2rem;
            font-weight: 900;
            z-index: 1;
            margin-bottom: 1.5rem;
            @media screen and (min-width: 1024px) {
              width: 500px;
              position: absolute;
              top: 140px;
              left: 40px;
              font-size: 4rem;
            }
          }

          img {
            max-width: 100%;
            filter: grayscale(1);
            position: relative;
          }
        }
      }
    }
  }

  .exp__about_me {
    padding-top: 6rem;
    width: 100%;
    @media screen and (min-width: 1024px) {
      padding-top: 16rem;
    }
    .exp__about_wrap {
      .stats_bio {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        @media screen and (min-width: 1024px) {
          width: 820px;
        }
        h3 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 2rem;
          line-height: 26px;
          font-weight: 700;
          @media screen and (min-width: 1024px) {
            font-size: 2.8rem;
            line-height: 36px;
          }
          .hglit {
            display: inline-block;
            padding: 5px 10px;
            background: var(--color-secondary);
            color: #fff;
          }
        }
      }
    }
  }
}
