.nav__links {
  padding: 0;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  overflow: hidden;
  h4 {
    position: relative;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    color: var(--color-secondary);
    transition: all 0.3s ease;
    &::before {
      content: none;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.4rem;
      line-height: 3rem;
      padding-right: 2rem;
      &::before {
        content: "";
        width: 0;
        height: 2px;
        position: absolute;
        left: 100%;
        top: 50%;
        background-color: var(--color-secondary-1);
        transition: width 0.5s;
      }
    }
  }
  &:hover,
  &.active {
    h4 {
      color: var(--color-secondary-1);
      &::before {
        width: 100px;
      }
    }
  }
}
