a.link__button,
.submit__button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0.75rem 1.75rem;
  border-radius: 4px;
  border: none;
  background-color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-white);
  font-family: var(--font-base);
  text-decoration: none;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;

  span {
    margin-right: 5px;
  }

  svg {
    transition: all 0.1s;
  }

  &:hover {
    background-color: var(--color-white);
    border: 2px solid var(--color-secondary-1);
    color: var(--color-secondary-1);

    svg {
      transform: rotate(45deg);
    }
  }
}
