.app__blogs-page {
  background-color: var(--color-white);
  .app__blogs {
    padding-bottom: 80px;
    .app__blog-list {
      display: grid;
      grid-template-columns: auto;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      .app__blogs-item {
        margin: 0;
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        gap: 2rem;
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        gap: 3rem;
      }
    }
  }
}
