.textImage_wrap {
  margin-top: 2rem;
  padding: 2rem 0 1rem;
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    padding: 15rem 0 0;
    overflow: hidden;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 8rem;
  }
  .content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 100px;
    padding: 0;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
    &.is-right {
      flex-direction: column;
      @media screen and (min-width: 1024px) {
        flex-direction: row-reverse;
      }
    }
    .image_wrapper {
      display: block;
      width: 100%;
      position: relative;
      max-width: 100%;
      margin-bottom: 2rem;
      @media screen and (min-width: 1024px) {
        max-width: 70%;
      }
      .visual_wrapper {
        max-height: 40rem;
        overflow: hidden;
        img {
          display: block;
          object-position: center center;
          max-width: 100%;
        }
      }
    }
    .copy_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 100%;
      @media screen and (min-width: 1024px) {
        max-width: 30%;
      }
      .title {
        font-size: 1.8rem;
        font-weight: 200;
        display: block;
        margin-bottom: 2.8rem;
        text-transform: capitalize;
        color: var(--color-secondary);
        @media screen and (min-width: 1024px) {
          font-size: 2.5rem;
          font-weight: 400;
        }
      }
      .subTitle {
        font-size: 1.4rem;
        font-weight: 300;
        display: block;
        line-height: 1.5;
        @media screen and (min-width: 1024px) {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 2rem;
          line-height: 2.2rem;
        }
      }
      .copy {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.5;
        margin: 3.5rem 0 3rem;
        @media screen and (min-width: 1024px) {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }
  }
}
