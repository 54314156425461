.app__navbar {
  width: 100%;
  max-height: 80px;
  background: var(--color-background-navbar);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid var(--color-border-navbar);
  position: fixed;
  z-index: 2;

  .container {
    max-width: 95%;
    border-bottom: 1px solid var(--color-grey-4);

    @media screen and (min-width: 1024px) {
      max-width: 1180px;
    }
  }
}

.navbar__wrap {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button.mobile_nav_btn {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 1rem;
    background: transparent;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.app__navbar-logo {
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  gap: 1.4rem;

  li {
    // margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      @media screen and (min-width: 1024px) {
        font-family: var(--font-secondary);
        font-size: 1.4rem;
        color: var(--color-grey);
        text-decoration: none;
        flex-direction: column;
        font-weight: 500;
        padding: 6px 10px;
        position: relative;
        height: min-content;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 2.4rem;
        will-change: transform;
        transition: all 0.3s ease-in-out;

        &:hover,
        &.active {
          color: var(--color-primary);
          border-color: var(--color-secondary-1);
          background-color: rgba($color: var(--color-white), $alpha: 0.1);
        }
      }
    }

    &:hover {
      div {
        background: var(--color-secondary-1);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.mobile_navbar-menus {
  position: absolute;
  overflow: hidden;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 45vh;
  background: var(--color-white);
}
