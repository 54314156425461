.app__footer {
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 4rem 2rem;
  @media screen and (min-width: 1024px) {
    padding: 4rem 6rem;
  }
  .app__footer_content {
    .foot__content_one {
      text-align: center;
      padding-bottom: 3rem;
      border-bottom: 1px solid var(--color-grey-6);
      h2 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        @media screen and (min-width: 1024px) {
          font-size: 3.5rem;
        }
      }
      p {
        font-size: 1.4rem;
        margin-bottom: 2rem;
        @media screen and (min-width: 1024px) {
          font-size: 1.6rem;
        }
        br {
          display: none;
          @media screen and (min-width: 1024px) {
            display: block;
          }
        }
      }
    }
    .foot__content_two {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 2.5rem 0 0;
      @media screen and (min-width: 1024px) {
        flex-direction: row;
      }
      .foot__logo {
        width: 100px;
        height: auto;
        margin-bottom: 1rem;
        @media screen and (min-width: 1024px) {
          width: 150px;
        }
        img {
          max-width: 100%;
          object-fit: contain;
        }
      }
      .foot_links {
        display: flex;
        flex-direction: row;
        @media screen and (min-width: 1024px) {
          flex-direction: column;
        }
        &.in-mobile-view {
          display: none;
          @media screen and (min-width: 1024px) {
            display: flex;
          }
        }
        h4 {
          display: none;
          font-size: 1.6rem;
          font-weight: 600;
          margin-bottom: 1rem;
          @media screen and (min-width: 1024px) {
            display: block;
          }
        }
        a {
          color: var(--color-white);
          opacity: 0.5;
          padding-top: 5px;
          padding-bottom: 5px;
          margin: 0 5px;
          font-size: 1.3rem;
          font-weight: 400;
          text-decoration: none;
          transition: opacity 0.3s;

          span > svg {
            font-size: 2rem;
            margin: 0 0.5rem;
          }

          &:hover {
            opacity: 1;
          }
        }
        .cpy_right {
          color: var(--color-white);
          opacity: 0.5;
          font-size: 1.3rem;
          line-height: 1.5;
          br {
            display: none;
            @media screen and (min-width: 1024px) {
              display: block;
            }
          }
        }
      }
    }
  }
}
