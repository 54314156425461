ul.mobile_nav_items {
  padding: 25px;
  position: absolute;
  top: 0;
  width: 100%;
}

li.mobile_nav_item {
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  .m_nav_link {
    width: 95%;
    text-decoration: none;
    color: var(--color-black-3);
    padding: 1.5rem;
    text-align: left;

    text-transform: capitalize;
    font-size: 1.6rem;

    &.active {
      color: var(--color-primary);
    }
  }
}
