#hero {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  backdrop-filter: blur(10px);

  .app__wrapper {
    padding: 0;

    .app__header {
      flex: 1;
      width: 100%;
      height: 100%;
      padding: 7.5rem 0 0;

      @media screen and (min-width: 768px) {
        padding: 0;
      }

      @media screen and (min-width: 1024px) {
        padding: 2rem 2rem 0;
      }

      .app__header_main-info {
        display: block;
        margin-bottom: 5rem;
        .app__header_main-info-img {
          margin-bottom: 3rem;
          .image_container {
            background: #040300;
            width: 100%;
            text-align: center;
            @media screen and (min-width: 768px) {
              align-items: center;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              gap: 10px;
              height: 450px;
              justify-content: flex-end;
              overflow: hidden;
              padding: 0;
              position: relative;
              // width: 400px;
              margin-left: auto;
              // margin-top: 5rem;
            }

            @media screen and (min-width: 1024px) {
              align-items: center;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              gap: 10px;
              height: 500px;
              justify-content: center;
              overflow: hidden;
              padding: 0;
              position: relative;
              width: 450px;
            }
            img.hero_profile-img {
              width: 185px;
              @media screen and (min-width: 768px) {
                width: 360px;
                max-width: 100%;
                object-fit: cover;
                object-position: center center;
                border-radius: 0.1rem;
                box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
              }
              @media screen and (min-width: 1024px) {
                width: auto;
                max-width: 100%;
                object-fit: cover;
                object-position: center center;
                border-radius: 0.1rem;
                box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
              }
            }
          }
          @media screen and (min-width: 768px) {
            margin-bottom: 6rem;
            margin-top: 8rem;
          }
          @media screen and (min-width: 1024px) {
            margin-bottom: 4rem;
          }

          .tablet_txt {
            display: none;
            @media screen and (min-width: 768px) {
              display: block;
              position: absolute;
              top: 22%;
              left: 5%;
              p {
                text-transform: capitalize;
                font-size: 1.6rem;
                font-weight: 500;
                color: var(--color-grey);
                margin-bottom: 5rem;
              }
              h1 {
                font-size: 6rem;
                font-family: var(--font-cursive);
                line-height: 6rem;
                font-weight: 500;
                color: var(--color-secondary);
              }
            }
            @media screen and (min-width: 1024px) {
              display: none;
            }
          }

          @media screen and (min-width: 1024px) {
            background: transparent;
            width: auto;
            text-align: initial;
            margin: 0;

            flex: 1;
            display: flex;
            justify-content: center;
          }
        }
        .app__header_main-info-content {
          margin-bottom: 5rem;
          h1 {
            margin: 0;
          }
          .hello-txt {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 500;
            color: #000;
            margin-bottom: 2rem;

            br {
              display: none;
            }
            @media screen and (min-width: 768px) {
              display: none;
            }
            @media screen and (min-width: 1024px) {
              display: block;
              font-size: 2.4rem;
              font-weight: 400;
              text-transform: none;
              margin-bottom: 3.5rem;
            }
          }
          .big-name {
            font-size: 6rem;
            font-family: var(--font-cursive);
            line-height: 6rem;
            font-style: normal;
            font-weight: 500;
            color: var(--color-secondary);
            margin-bottom: 2rem;
            @media screen and (min-width: 768px) {
              display: none;
            }

            @media screen and (min-width: 1024px) {
              display: block;
              font-size: 6.4rem;
              margin-bottom: 3rem;
            }
          }
          .desc-text {
            width: 100%;
            font-size: 1.8rem;
            line-height: 1.4;
            margin-bottom: 4rem;

            @media screen and (min-width: 768px) {
              font-size: 2rem;
            }

            @media screen and (min-width: 1024px) {
              font-size: 2rem;
              line-height: 160%;
              letter-spacing: 0em;
              margin-bottom: 5rem;
            }
          }

          @media screen and (min-width: 1024) {
            margin-bottom: 0;
            flex: 1;
            padding-top: 2.5rem;
          }
        }
        .page-heading-desc {
          display: block;
          .page-heading-desc-text {
            max-width: 370px;
            font-size: 1.6rem;
            a {
              text-decoration: none;
              color: var(--color-secondary);
            }
          }
        }

        @media screen and (min-width: 1024px) {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 0 6rem;

          .app__header_main-info-content {
            .desc-text {
              max-width: 60rem;
            }
          }
        }
      }
    }
  }
}
