.page__header {
  padding-top: 211px;
  padding-bottom: 70px;
  .page__header_heading_big {
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 700;
    font-size: 6.6rem;
    line-height: 80px;
    color: var(--color-black);
    margin-bottom: 100px;
    @media screen and (min-width: 425px) {
      font-size: 9.6rem;
      line-height: 134px;
    }
  }

  @media screen and (min-width: 1024px) {
    .page__header_heading_big {
      font-size: 10.6rem;
    }
  }
}
