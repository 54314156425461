.app__recipe--item {
  -webkit-box-flex: 0;
  width: 100%;
  position: relative;
  @media screen and (min-width: 1024px) {
    min-width: 270px;
    cursor: pointer;
  }
  .recipe-image {
    overflow: hidden;
    border-radius: 0.4rem;
    @media screen and (min-width: 1024px) {
      max-height: 330px;
      border-radius: 1.25rem;
    }
    img {
      display: block;
      max-width: 100%;
      object-position: center;
      object-fit: cover;
      transition: transform 0.35s;
    }
  }
  .recipe-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.4rem 3rem 1.9rem 1.9rem;
    opacity: 0;
    visibility: hidden;

    font-size: 1.8rem;
    color: var(--color-white);
    z-index: 1;
    transition: opacity 3s;

    .recipe-title {
      font-size: 1.8rem;
    }
    .recipe-desc {
      font-size: 1.3rem;
    }
  }
  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 1.25rem;
    background: rgba($color: var(--color-black), $alpha: 0.5);
    transition: opacity 0.3s;
  }
  &:hover {
    .recipe-image {
      img {
        transform: scale(1.1);
      }
    }
    .recipe-info {
      opacity: 1;
      visibility: visible;
    }
    &::after {
      opacity: 1;
    }
  }
}
