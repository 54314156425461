.app_single_recipe-page {
  .app_single_recipe {
    .container {
      padding: 0 3rem;
      @media screen and (min-width: 1024px) {
        padding: 0 6rem;
      }
      .recipe_header {
        padding: 9rem 0 0;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 4rem;
        @media screen and (min-width: 1024px) {
          width: 65%;
        }
        h1 {
          font-size: 2.8rem;
          text-align: center;
          @media screen and (min-width: 1024px) {
            font-size: 4.8rem;
          }
        }
      }
      .recipe_img {
        position: relative;
        margin-bottom: 2rem;
        @media screen and (min-width: 1024px) {
          margin-bottom: 4rem;
        }
        .img_wrap {
          height: 250px;
          border-radius: 0.8rem;
          // box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 10px
            rgba($color: var(--color-black), $alpha: 0.1);
          overflow: hidden;
          @media screen and (min-width: 1024px) {
            height: 450px;
            border-radius: 1.25rem;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .recipe_description {
        margin-bottom: 2rem;
        @media screen and (min-width: 1024px) {
          margin-bottom: 4rem;
        }
        p {
          font-size: 1.6rem;
          line-height: 2.5rem;
          @media screen and (min-width: 1024px) {
            font-size: 1.8rem;
            line-height: 3rem;
          }
        }
      }
      .recipe_detail {
        display: inline-block;
        width: 100%;
        margin-bottom: 2rem;
        @media screen and (min-width: 1024px) {
          margin-bottom: 4rem;
        }
        ul {
          width: 100%;
          list-style-type: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: normal;
          align-content: center;
          margin: 0 auto;
          border-radius: 0.8rem;
          background: rgba(255, 255, 255, 0.5);
          // background: rgba($color: var(--color-white), $alpha: 0.2);
          backdrop-filter: blur(4px);
          -webkit-backdrop-filter: blur(4px);
          border: 1px dotted rgba(0, 0, 0, 0.2);
          @media screen and (min-width: 1024px) {
            width: 65%;
            flex-wrap: nowrap;
            justify-content: space-between;
          }
          li {
            width: 33.33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            &:last-child {
              border-right: 1px solid rgba(0, 0, 0, 0.2);
              @media screen and (min-width: 1024px) {
                border: none;
              }
            }
            @media screen and (min-width: 1024px) {
              width: 100%;
              border-bottom: none;
            }
            .recipe_crd {
              padding: 1.2rem 0;
              display: inline-block;
              line-height: 1.6rem;
              width: 100%;
              height: 100%;
              text-align: center;
              overflow: hidden;
              font-size: 1.4rem;
              font-weight: 600;
              .recipe_crd_ico {
                font-size: 3rem;
                margin-bottom: 0.2rem;
              }
              .recipe_crd_txt1 {
                font-size: 1.4rem;
                font-weight: 400;
              }
              .recipe_crd_txt2 {
                font-size: 1.4rem;
                font-weight: 700;
              }
            }
          }
        }
      }
      .recipe_ings {
        display: inline-block;
        width: 100%;
        min-height: 42rem;
        margin-bottom: 3rem;
        @media screen and (min-width: 1024px) {
          margin-bottom: 4rem;
        }
        .ingredients_wrap {
          width: 100%;
          border-radius: 1.2rem;
          position: relative;
          margin: auto;
          background: url("../../assets/recipe_bg.svg") repeat;
          background-size: cover;
          @media screen and (min-width: 1024px) {
            width: 60%;
          }
          img {
            display: none;
            @media screen and (min-width: 1024px) {
              display: block;
              position: absolute;
              right: -97px;
              bottom: 0;
              width: 115px;
            }
          }
          .ings_elem-wrp {
            margin: 0;
            width: 100%;
            min-height: 42rem;
            padding: 0.4rem;
            position: relative;

            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.15);

            backdrop-filter: blur(5px);

            z-index: 0;
          }
          .ings_elem-txt {
            padding: 2.2rem 3.2rem 1.8rem;
            width: 100%;
            margin: 0;
            border-radius: 1.2rem;
            h3 {
              display: inline-block;
              font-size: 2rem;
              font-weight: 700;
              line-height: 2.1rem;
              text-transform: uppercase;
              margin-bottom: 1.8rem;
            }
            .ing_elements {
              max-height: 355px;
              overflow: hidden;
              overflow-y: auto;
              ul {
                list-style: none;
                li {
                  position: relative;
                  font-size: 1.6rem;
                  line-height: 2.5rem;
                  border-bottom: 1px solid var(--color-white-6);
                  padding-bottom: 0.8rem;
                  padding-top: 0.8rem;
                  padding-left: 3rem;
                  &:last-child {
                    border: none;
                  }
                  &::before {
                    content: "";
                    width: 1.5rem;
                    height: 1.5rem;
                    position: absolute;
                    background: url("../../assets/tomato_point.png") no-repeat;
                    background-position: center;
                    background-size: contain;
                    top: 1.2rem;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
      .how_to_make {
        margin-bottom: 3rem;
        @media screen and (min-width: 1024px) {
          margin-bottom: 4rem;
        }
        .method_title {
          display: flex;
          padding-bottom: 8px;
          margin-bottom: 20px;
          align-items: center;
          border-bottom: 1px solid var(--color-white-6);
          justify-content: space-between;
          white-space: nowrap;
          width: 100%;
          h1 {
            font-size: 2rem;
            line-height: 2.5rem;
            font-weight: 700;
            margin-right: 0.8rem;
            margin-bottom: 0;
            white-space: normal;
            width: auto;
            @media screen and (min-width: 1024px) {
              font-size: 2.2rem;
            }
          }
        }
        .li_content {
          display: flex;
          width: 100%;
          font-size: 1.6rem;
          line-height: 2.7rem;
          padding-bottom: 1rem;
          position: relative;
          margin-bottom: 1.2rem;
          border-bottom: 1px solid var(--color-white-6);
          @media screen and (min-width: 1024px) {
            font-size: 1.8rem;
          }
          &:last-child {
            border: none;
          }
          .li_count {
            position: absolute;
            font-size: 1.6rem;
            font-weight: 700;
            left: 2px;
            top: 0;
            @media screen and (min-width: 1024px) {
              font-size: 1.8rem;
            }
          }
          .li_wrap {
            display: inline-block;
            padding-left: 40px;
            .li_text {
              display: inline-block;
              width: 100%;
            }
          }
        }
      }
      .recipe_tags {
        width: 100%;
        display: flex;
        margin: 0 0 4rem;
        .tags_title {
          display: inline-block;
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 3.6rem;
          margin-right: 0;
          position: relative;
          text-transform: capitalize;
          letter-spacing: 0.7px;
          white-space: nowrap;
        }
        .tags_scroll {
          padding-left: 0.8rem;
          overflow-x: scroll;
          -webkit-overflow-scrolling: touch;
          overflow-y: hidden;
          .tags_scroll_cont {
            display: flex;
            flex-wrap: nowrap;
            font-size: 0;
            white-space: normal;
            .tags_li {
              .tags_li_txt {
                padding: 0 1.2rem;
                display: inline-block;
                opacity: 1;
                position: relative;

                background-color: var(--color-white-6);
                border: 1px solid var(--color-white-6);
                border-radius: 2.5rem;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 3.6rem;
                margin: 0 6px 4px 0;
                white-space: nowrap;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}
