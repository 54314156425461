#blogs {
  .app__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .app__flex {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.app__blogs-container {
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: 1fr;
  gap: 3rem;

  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .app__blog-item {
    padding: 0;
    margin: 0;
    display: block;
  }
}

.app__blogs_foot {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
