#works {
  .app__wrapper {
    .app__works {
      margin-top: 6rem;
      .app__works-wrapper {
        .app__works--items {
          display: grid;
          grid-template-columns: auto;
          grid-auto-rows: 1fr;
          padding-bottom: 0;
          justify-content: center;
          align-items: center;
          gap: 3rem;
          @media screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: min-content;
            padding: 0;
            position: relative;
            width: 100%;
          }
          @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
    }
    .app__works_foot {
      margin-top: 4rem;
      text-align: center;
    }
  }
}
