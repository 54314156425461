.app_single_blog-page {
  .app_single_blog {
    .container {
      padding: 0 3rem;
      @media screen and (min-width: 1024px) {
        padding: 0 6rem;
      }
      header {
        padding: calc(30px + (60 - 30) * ((100vw - 375px) / (1600 - 375))) 0;
        @media screen and (min-width: 1024px) {
          padding: 60px 0;
        }
        .blog_header {
          padding: 6rem 0 0;
          width: 100%;
          margin: 0 auto;
          margin-bottom: 4rem;
          @media screen and (min-width: 1024px) {
            width: 65%;
          }
          h1 {
            font-size: 3rem;
            text-align: center;
            margin-bottom: 20px;
            @media screen and (min-width: 1024px) {
              font-size: 4.8rem;
            }
          }
          .blog_author {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 auto
              calc(30px + (60 - 30) * ((100vw - 375px) / (1600 - 375)));
            font-size: 14px;
            @media screen and (min-width: 1024px) {
              margin: 0 auto 60px;
            }
            .author {
              font-weight: 500;
            }
            .divider {
              display: none;
              color: rgb(211, 213, 219);
              margin: 0px 6px;
              @media screen and (min-width: 768px) {
                display: inline-block;
              }
            }
            .date {
              flex: 0 0 100%;
              color: #555555;
              text-align: center;
              margin: 10px 0px 0px;
              @media screen and (min-width: 768px) {
                flex: 0 0 auto;
                margin: 0px;
                text-align: left;
              }
            }
          }
        }
        .blog_image {
          width: 100%;
          margin: 0 auto;
          @media screen and (min-width: 1024px) {
            width: 88%;
          }
          .blog_image_frame {
            border-radius: 8px;
            overflow: hidden;
            img {
              max-width: 100%;
              border-radius: 8px;
            }
          }
        }
      }
      .blog_content-wrapper {
        position: relative;
        .body_content-body {
          margin-top: 0px;
          margin-right: auto;
          margin-left: auto;
          max-width: 770px;
          width: 100%;
          padding: 0px;
          margin-bottom: 60px;
          position: relative;
          .content {
            color: #314351;
            p {
              margin-bottom: 2.3rem;
              font-size: 1.6rem;
              line-height: 1.8;
              &:first-of-type {
                font-size: 1.8rem;
                @media screen and (min-width: 1024px) {
                  font-size: 2rem;
                }
              }
              @media screen and (min-width: 1024px) {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}
