.app__recipes-page {
  background-color: var(--color-white);
  .app__recipes {
    padding-bottom: 80px;
    .app__recipes-wrap {
      .app__recipes-list {
        display: grid;
        grid-template-columns: auto auto;
        gap: 2rem;
        @media screen and (min-width: 1024px) {
          grid-template-columns: auto auto auto;
          gap: 3rem;
        }
      }
    }
  }
}
