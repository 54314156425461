.input__group {
  margin-bottom: 1rem;
  label {
    font-size: 1.4rem;
    display: block;
    margin-bottom: 4px;
  }
  input,
  textarea {
    min-height: 40px;
    width: 100%;
    border: 2px solid var(--color-grey-3);
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
    font-family: var(--font-base);
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--color-grey-2);
    outline: none;
    resize: none;
  }
  select {
    min-height: 40px;
    width: 100%;
    border: 2px solid var(--color-grey-3);
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    color: var(--color-grey-2);
    outline: none;
    option {
      color: var(--color-grey-2);
    }
  }
}
