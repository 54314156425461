#testimonial {
  .app__wrapper {
    .app__testimonials {
      margin-top: 6rem;
      .prevCTA,
      .nextCTA {
        font-size: 1.5rem;
        cursor: pointer;
        display: none;
        @media screen and (min-width: 1024px) {
          display: block;
        }
      }
      .testimonial__box_wrap {
        display: flex;
        width: 100%;
        min-height: 300px;
        margin-right: 0;
        margin-left: 0;
        padding: 3rem 5rem;
        align-items: center;
        border-radius: 1rem;
        background-color: var(--color-white);
        @media screen and (min-width: 1024px) {
          width: 800px;
          margin-right: 4rem;
          margin-left: 4rem;
          padding: 50px 60px;
          border-radius: 15px;
        }
        .testimonial__box_item {
          display: grid;
          align-items: center;
          grid-auto-columns: 1fr;
          gap: 16px;
          grid-template-rows: auto;
          @media screen and (min-width: 1024px) {
            grid-template-columns: 150px 1fr;
          }
          .testimonial_circle {
            width: 115px;
            height: 115px;
            border-radius: 50%;
            background-color: var(--color-primary);
            box-shadow: 7px 5px 10px 2px var(--color-white-3);

            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transform: translate(-3%, -5%);
            @media screen and (min-width: 1024px) {
              width: 155px;
              height: 155px;
            }
          }
          .testimonial_circle-sm {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: var(--color-primary);
            box-shadow: 1px 1px 10px 2px var(--color-white-3);

            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transform: translate(-20%, -20%);
            @media screen and (min-width: 1024px) {
              width: 110px;
              height: 110px;
            }
          }
          .testimonial_circle-xs {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: var(--white-color);
            box-shadow: 1px 1px 10px 2px var(--color-white-3);

            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            transform: translate(155%, 120%);
            @media screen and (min-width: 1024px) {
              width: 75px;
              height: 75px;
            }
          }
          .testimonial__person {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            position: relative;
            z-index: 10;
            @media screen and (min-width: 1024px) {
              width: 150px;
              height: 150px;
            }
          }
          h5 {
            font-size: 1.6rem;
            font-family: var(--font-secondary);
            line-height: 2rem;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 1.8rem;
            @media screen and (min-width: 1024px) {
              font-size: 2rem;
              line-height: 28px;
              font-weight: 700;
            }
          }
          .testimonial__person_name {
            font-size: 1.6rem;
            font-weight: 700;
            color: var(--color-secondary-1);
            @media screen and (min-width: 1024px) {
              font-size: 1.8rem;
            }
          }
          .testimonial__person_role {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
