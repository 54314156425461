.app__recipes-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  top: 80px;
  border-radius: 1rem;
  background-color: var(--color-white-2);
  padding: 5px 0;
  z-index: 99;
  margin: 2rem 0 2rem;

  .app__recipes-filter-item {
    position: relative;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    color: var(--color-grey-5);
    font-weight: 800;
    cursor: pointer;

    .activePill {
      position: absolute;
      background-color: var(--color-secondary);
      inset: 0;
      border-radius: 1rem;
      box-shadow: 1px 1px 20px 1px rgba($color: var(--color-black), $alpha: 0.2);
    }

    .activePillLabel {
      position: relative;
    }
  }
  .item-active {
    color: var(--color-white);
  }
}
