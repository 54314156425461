.app__blog-item-wrap {
  border-radius: 1.2rem;
  width: 100%;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #ecf0f4;
  }

  .app__blog-inner {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    margin: 0;
    overflow: hidden;
    padding: 1.2rem;
    .blog__item-img {
      border-radius: 1.2rem;
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 1.2rem;
      }
    }

    .blog__item-content {
      width: 100%;
      padding: 0 1.2rem;
      .published_at {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 200;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .item_title {
        display: -webkit-box;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.3rem;
        color: var(--color-black-2);
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .item_desc {
        display: -webkit-box;
        margin-top: 0.5rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: var(--color-grey);
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;
    img {
      height: 320px;
    }
  }
}
